// Generated by Framer (3140034)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["pEGEJeMdf"];

const variantClassNames = {pEGEJeMdf: "framer-v-pncndo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "pEGEJeMdf", title: LkPq2DzEn = "Work", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "pEGEJeMdf", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-2CJRy", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-pncndo", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"pEGEJeMdf"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLXJlZ3VsYXI=", "--framer-font-family": "\"Open Sans\", sans-serif", "--framer-font-size": "24px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Work</motion.p></React.Fragment>} className={"framer-131lav7"} data-framer-name={"Button label"} fonts={["GF;Open Sans-regular"]} layoutDependency={layoutDependency} layoutId={"P1ptL1TdX"} style={{"--extracted-r6o4lv": "rgb(102, 102, 102)", "--framer-paragraph-spacing": "0px"}} text={LkPq2DzEn} transformTemplate={(_, t) => `translate(-50%, -50%) ${t}`} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-2CJRy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2CJRy * { box-sizing: border-box; }", ".framer-2CJRy .framer-1ijf9ez { display: block; }", ".framer-2CJRy .framer-pncndo { height: 44px; overflow: visible; position: relative; width: 93px; }", ".framer-2CJRy .framer-131lav7 { flex: none; height: auto; left: 49%; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 93
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"LkPq2DzEn":"title"}
 */
const FramerZ_ZPHmeeX: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerZ_ZPHmeeX;

FramerZ_ZPHmeeX.displayName = "Nav Link";

FramerZ_ZPHmeeX.defaultProps = {height: 44, width: 93};

addPropertyControls(FramerZ_ZPHmeeX, {LkPq2DzEn: {defaultValue: "Work", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerZ_ZPHmeeX, [{family: "Open Sans", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/Z_ZPHmeeX:default", url: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf", weight: "400"}])